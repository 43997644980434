<template>
  <div class="indexqi">

    <div class="formboxalready formbox">
      <div>
        <div class="inputbox flex">
          <p>企业名称：</p>
          <el-input
            placeholder="请输入企业名称"
            v-model="info.name"
            clearable
            :disabled="disabled"
          >
          </el-input>
        </div>
      

        <div class="inputbox flex">
          <p>企业联系人：</p>
          <el-input
            placeholder="请输入企业联系人姓名"
            v-model="info.lxr"
            clearable
            :disabled="disabled"
          >
          </el-input>
        </div>

        <div class="inputbox flex">
          <p>联系方式：</p>
          <el-input
            placeholder="联系人手机号"
            v-model="info.phone"
            clearable
            :disabled="disabled"
          >
          </el-input>
        </div>
       

      <div class="inputbox flex">
        <p>所在地区：</p>
        <el-cascader
          v-model="Dataval"
          :options="CityInfo"
          :props="TagProps"
          placeholder="请选择区域"
          @change="handleChange"
          :disabled="disabled"
          >
        </el-cascader>
      </div>

      <div class="inputbox">
          <div class="lable">营业执照：</div>
          <div class="idbox">
            <el-upload
              class="avatar-uploader"
              action
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="
                (file) => {
                  uploadSectionFile(file, 4)
                }
              "
              :disabled="disabled"
            >
              <img v-if="imageUrlid4" :src="imageUrlid4" class="idimg4" />
              <div v-else>
                <img src="@/assets/img/up2.png" alt="" class="idimg4" />
              </div>
            </el-upload>
          </div>
        </div>

       
        <div class=" shenqinginput">
          <p style="margin-bottom:13px">主营业务简介：</p>
          <el-input
            type="textarea"
            placeholder="请填写"
            v-model="info.content"
            clearable
            resize="none"
            :autosize="{ minRows: 5, maxRows: 10}"
            :disabled="disabled"
          >
          </el-input>
        </div>

        <div class="jujue" v-if="info.status == 2">
				  拒绝原因： <span>{{info.jujue}}</span>
			  </div>
       
        <div class="btnbox"  @click="change" v-if="status == 2">修改信息</div>
				<div class="btnbox"  @click="change" v-else-if="status == 1">修改认证</div>
				<div class="btnbox disabledBtn" v-else-if="status == 0">审核中</div>
				<div class="btnbox" @click="submit" v-else>提交认证</div>

        <!-- 认证图标 au2.png已认证   au1.png认证失败-->
        <img v-if="status == 1" src="@/assets/img/au2.png" class="rzimg"/>
        <img v-if="status == 2" src="@/assets/img/au1.png" class="rzimg" />
      </div>
   
    </div>
  </div>
</template>

<script>

import loginUrl from '@/api/login'
// import apiUrl from '@/api/main'

import Dataoptions  from '@/util/Data'

export default {
  name: '',
  components: {},
  data() {
    return {
      isauthen: null, // false 未认证  true 已认证
      readonly: false, // 是否只读 审核中 true
   
      Gephone: '',
      info: { },
      imageUrlid4: '',

      // 省市区
      provinceVal: '', // 省
      cityVal: '', // 市
      areaVal: '', // 区
     
      cityList: [],
      areaList: [],
      redirect: undefined,
      // 三级联动 省市区
      CityInfo: Dataoptions,
      Dataval:[],
      // 更改组件默认的数据键
      TagProps:{
        value: 'label',
        label: 'label',
        children: 'children',
      },
      status:'', // 空 3 未提交  0未审核,审核中  1审核通过  2拒绝
      disabled:false,
       
    }
  },

  mounted() {
    /*
      status 空3 未提交  0未审核,审核中  1审核通过  2拒绝
    */
    this.getuserinfo();


    let a = this.CityInfo.shift();

    console.log(a)

  },

  methods: {

    // 获取企业详情 是否认证
    getuserinfo() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      loginUrl.qiyeinfo(data).then((res) => {
        if(res.data){
						this.info = res.data;
						this.imageUrlid4 = res.data.yyzz;
            this.Dataval = [res.data.provinceVal, res.data.cityVal]
            this.provinceVal = res.data.provinceVal;
            this.cityVal = res.data.cityVal;
						// 审核中
						if(res.data.status == 0 || res.data.status == 1 || res.data.status == 2){
							this.disabled = true;
						  this.status = res.data.status;
						}
					}else{
						// 未认证
						this.info.status = 3;
            this.status = 3;
					}
      })
    },
    // 上传图片  4 营业执照
    uploadSectionFile(param, num) {
      var fileObj = param.file
      var from = new FormData()
      from.append('file', fileObj)

      loginUrl.upload(from).then((res) => {
        if (res.code == 0) {
          this.$message.success('上传成功')
          this[`imageUrlid${num}`] = res.url
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      // return isJPG && isLt2M;
      return isLt20M
    },
    change(){
      this.status = 3;
      this.disabled = false;
    },

    submit() {
      if (!this.info.name) {
        this.$message.error('请输入企业名称')
        return
      }
    
    
      if (!this.info.lxr) {
        this.$message.error('请输入联系人姓名')
        return
      }
      if (!this.info.phone) {
        this.$message.error('请输入联系人手机号')
        return
      }
      // 验证手机号
      if (!/^1[3456789]{1}\d{9}$/gi.test(this.info.phone)) {
        this.$message.error('手机号格式不正确')
        return false
      }

      if (!this.provinceVal) {
        this.$message.error('请选择区域')
        return
      }
  
      if (!this.imageUrlid4) {
        this.$message.error('请上传企业营业执照图片')
        return
      }

      let data = {
        userId:localStorage.getItem('userId'),
        name:this.info.name,
        lxr:this.info.lxr,
        phone:this.info.phone,
        addr:this.addr,
        yyzz:this.imageUrlid4,
        content:this.info.content,
        provinceVal:this.provinceVal, // pc 端需要用到
        cityVal:this.cityVal, // pc 端需要用到
      }
     
      // 认证成功或失败后 修改认证信息
      if(this.info.status == 1 || this.info.status == 2){
        loginUrl.qiyeedit(data).then((res) => {
          if (res.code == 0) {
            this.$message.success('提交成功')
            this.getuserinfo()
          }
        })
      }else {
        loginUrl.qiye(data).then((res) => {
          if (res.code == 0) {
            this.$message.success('提交成功')
            this.getuserinfo()
          }
        })
      }
    },

    handleChange(value){
      this.info.addr = value[0]+value[1];
      this.provinceVal = value[0];
      this.cityVal = value[1];

    }
  },


}
</script>

<style lang='scss' >
.indexqi {
  padding: 33px 30px;
  background-color: #fff;
  .formbox {
    padding: 16px 70px;
    width: 700px;
    .inputbox {
      align-items: center;
      margin-bottom: 33px;
      p {
        width: 140px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      .lable {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }

      .idbox {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }
    .shenqinginput{
      align-content: flex-start;
      margin-bottom: 33px;
      p {
        width: 140px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    
    .idimg4 {
      width: 320px;
      height: 180px;
    }
  }

  // 已认证
  .formboxalready {
    padding: 16px 70px;
    position: relative;
    .rzimg {
      width: 146px;
      height: 128px;
      position: absolute;
      top: 26px;
      left: 247px;
    }
    .alreadybox {
      margin-bottom: 33px;
      p {
        font-size: 18px;
        font-weight: 500;
        color: #333333;

        span {
          margin-left: 23px;
        }
      }
    }
    .lable {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 20px;
    }
    .alidimg {
      width: 286px;
      height: 163px;
    }
    .relabox {
      position: relative;
      .rzimg {
        width: 119px;
        height: 104px;
        position: absolute;
        top: 0;
        left: 200px;
      }
    }

    .btnbox {
      font-size: 18px;
      width: 300px;
      height: 56px;
      line-height: 56px;
      margin-top: 65px;
    }
    .disabledBtn {
      background: lightgray !important;
      cursor: auto;
    }
  }

   
}

// 省市区
.selectbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
  .lable{
    width: 90px;
  }

  .el-select {
    width: 130px;
    margin: 0 5px;
  }
  .el-select:nth-child(1) {
    margin-left: 0px !important;
  }
  .el-select:nth-child(3) {
    margin-right: 0px !important;
  }
}

.el-cascader{
width: 100% !important;
}
.el-cascader-panel{
  max-height: 300px !important;
}
.jujue{
  span{
    color:#E00000;
  }
}
</style>